@unocss;

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  cursor: none;
}

.custom-cursor {
  width: 0.5em;
  height: 0.5em;
  background-color: #E7F2F4FF;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: all 0.2s ease;
  transition-property: background, border, transform;
  transform-origin: 150% 150%;
  z-index: 999;
}

a {
  animation: colorTransition 48s linear infinite;
}

@keyframes colorTransition {
  0% {
    color: lch(42% 100 24);
  }
  7% {
    color: lch(60% 100 28);
  }
  14% {
    color: lch(60% 100 35);
  }
  21% {
    color: lch(60% 100 55);
  }
  28% {
    color: lch(78% 100 73);
  }
  35% {
    color: lch(83% 100 88);
  }
  42% {
    color: lch(81% 100 112);
  }
  49% {
    color: lch(60% 100 135);
  }
  56% {
    color: lch(69% 100 165);
  }
  63% {
    color: lch(69% 100 193);
  }
  70% {
    color: lch(64% 100 235);
  }
  77% {
    color: lch(64% 100 289);
  }
  84% {
    color: lch(64% 100 305);
  }
  91% {
    color: lch(64% 100 345);
  }
  100% {
    color: lch(65% 100 18);
  }
}
