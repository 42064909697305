*, :before, :after {
  box-sizing: border-box;
  border-style: solid;
  border-width: 0;
  border-color: var(--un-default-border-color, #e5e7eb);
}

:before, :after {
  --un-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --un-rotate: 0;
  --un-rotate-x: 0;
  --un-rotate-y: 0;
  --un-rotate-z: 0;
  --un-scale-x: 1;
  --un-scale-y: 1;
  --un-scale-z: 1;
  --un-skew-x: 0;
  --un-skew-y: 0;
  --un-translate-x: 0;
  --un-translate-y: 0;
  --un-translate-z: 0;
  --un-pan-x: ;
  --un-pan-y: ;
  --un-pinch-zoom: ;
  --un-scroll-snap-strictness: proximity;
  --un-ordinal: ;
  --un-slashed-zero: ;
  --un-numeric-figure: ;
  --un-numeric-spacing: ;
  --un-numeric-fraction: ;
  --un-border-spacing-x: 0;
  --un-border-spacing-y: 0;
  --un-ring-offset-shadow: 0 0 #0000;
  --un-ring-shadow: 0 0 #0000;
  --un-shadow-inset: ;
  --un-shadow: 0 0 #0000;
  --un-ring-inset: ;
  --un-ring-offset-width: 0px;
  --un-ring-offset-color: #fff;
  --un-ring-width: 0px;
  --un-ring-color: #93c5fd80;
  --un-blur: ;
  --un-brightness: ;
  --un-contrast: ;
  --un-drop-shadow: ;
  --un-grayscale: ;
  --un-hue-rotate: ;
  --un-invert: ;
  --un-saturate: ;
  --un-sepia: ;
  --un-backdrop-blur: ;
  --un-backdrop-brightness: ;
  --un-backdrop-contrast: ;
  --un-backdrop-grayscale: ;
  --un-backdrop-hue-rotate: ;
  --un-backdrop-invert: ;
  --un-backdrop-opacity: ;
  --un-backdrop-saturate: ;
  --un-backdrop-sepia: ;
}

@font-face {
  font-family: Kode Mono;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/kodemono/v2/A2BLn5pb0QgtVEPFnlYkkaoBgw4qv9odq5myxDOZadW2.woff2") format("woff2");
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Kode Mono;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/kodemono/v2/A2BLn5pb0QgtVEPFnlYkkaoBgw4qv9odq5myxD2ZaQ.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Libre Barcode 128 Text";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://fonts.gstatic.com/s/librebarcode128text/v28/fdNv9tubt3ZEnz1Gu3I4-zppwZ9CWZ16Z0w5QVrS6Q.woff2") format("woff2");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.invisible {
  visibility: hidden;
}

.hidden {
  display: none;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.h1 {
  height: .25rem;
}

.h3 {
  height: .75rem;
}

.w-full {
  width: 100%;
}

.flex {
  display: flex;
}

.grow {
  flex-grow: 1;
}

.flex-col {
  flex-direction: column;
}

.place-content-center {
  place-content: center;
}

.items-center {
  align-items: center;
}

.gap-8 {
  gap: 2rem;
}

.bg-\[\#020202\] {
  --un-bg-opacity: 1;
  background-color: rgb(2 2 2 / var(--un-bg-opacity));
}

.bg-\[\#E7F2F4FF\] {
  --un-bg-opacity: 1;
  background-color: rgb(231 242 244 / var(--un-bg-opacity));
}

.px {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-\[24\%\] {
  padding-left: 24%;
  padding-right: 24%;
}

.py-\[4\%\] {
  padding-top: 4%;
  padding-bottom: 4%;
}

.pt-2xl {
  padding-top: 1.5rem;
}

.text-center {
  text-align: center;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-\[\#D5F2FFFF\] {
  --un-text-opacity: 1;
  color: rgb(213 242 255 / var(--un-text-opacity));
}

.font-barcode {
  font-family: "Libre Barcode 128 Text";
}

.font-kode {
  font-family: Kode Mono;
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.decoration-dotted {
  text-decoration-style: dotted;
}

@media (width >= 1024px) {
  .lg\:grid {
    display: grid;
  }

  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .lg\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-rows-8 {
    grid-template-rows: repeat(8, minmax(0, 1fr));
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:gap-2 {
    gap: .5rem;
  }

  .lg\:gap-px {
    gap: 1px;
  }

  .lg\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .lg\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .lg\:pt-0 {
    padding-top: 0;
  }

  .lg\:text-right {
    text-align: right;
  }

  .lg\:indent-8 {
    text-indent: 2rem;
  }

  .lg\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  cursor: none;
  margin: 0;
  padding: 0;
}

.custom-cursor {
  pointer-events: none;
  transform-origin: 150% 150%;
  z-index: 999;
  background-color: #e7f2f4;
  width: .5em;
  height: .5em;
  transition: background .2s, border .2s, transform .2s;
  position: absolute;
  transform: translate(-50%, -50%);
}

a {
  animation: 48s linear infinite colorTransition;
}

@keyframes colorTransition {
  0% {
    color: lch(42% 100 24);
  }

  7% {
    color: lch(60% 100 28);
  }

  14% {
    color: lch(60% 100 35);
  }

  21% {
    color: lch(60% 100 55);
  }

  28% {
    color: lch(78% 100 73);
  }

  35% {
    color: lch(83% 100 88);
  }

  42% {
    color: lch(81% 100 112);
  }

  49% {
    color: lch(60% 100 135);
  }

  56% {
    color: lch(69% 100 165);
  }

  63% {
    color: lch(69% 100 193);
  }

  70% {
    color: lch(64% 100 235);
  }

  77% {
    color: lch(64% 100 289);
  }

  84% {
    color: lch(64% 100 305);
  }

  91% {
    color: lch(64% 100 345);
  }

  100% {
    color: lch(65% 100 18);
  }
}
/*# sourceMappingURL=index.41292def.css.map */
